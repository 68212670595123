import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { CardActionArea } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation, Thumbs } from 'swiper/modules';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// import data
import { company_list } from '../data/base';
import { getCompany } from '../api/main';

const theme = createTheme();

theme.typography.h5 = {
	[theme.breakpoints.up('xs')]: {
		fontSize: '1rem',
	},
	[theme.breakpoints.up('sm')]: {
		fontSize: '1.7rem',
	},
	[theme.breakpoints.up('md')]: {
		fontSize: '1.7rem',
	},
	[theme.breakpoints.up('lg')]: {
		fontSize: '1.4rem',
	},
	[theme.breakpoints.up('xl')]: {
		fontSize: '22px',
	},
};

let selection = 0;

const CompCompanies = (props) => {
	const [company, setCompany] = useState([]);

	// For language selection
	const language = useSelector((state) => state.app.value.language);
	const dispatch = useDispatch();

	const navigate = useNavigate();

	const handleOpen = (id) => {
		navigate(`/company/${id}`);
		// selection = id - 1;
		// switch(id){
		// 	case '1':
		// 		navigate(`/company/fblc`);
		// 		break;
		// 	case '2':
		// 		navigate(`/company/tbb`);
		// 		break;
		// 	case '3':
		// 		navigate(`/company/dhh`);
		// 		// navigate(`/company/ijob`);
		// 		break;
		// 	default:
		// 		break;
		// }
	};

	useEffect(() => {
		console.log(language);
		getCompany(language).then(res => {
			setCompany(res.data);
		})
	}, [language]);

	const GeneralSectors = (props) => {
		return (
			<Box
				sx={{
					width: { xs: '100%', sm: '100%', md: '80%', lg: '80%', xl: '80%' },
					flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' },
					position: 'relative',
					m: 2
				}}
				onClick={() => { handleOpen(props.item.id) }} 
			>
				<Card elevation={2}
					sx={{
						height: {
							xs: '13rem',
							sm: '13rem',
							md: '15rem',
							lg: '15rem',
							xl: '15rem'
						},
						borderRadius: '20px',
						width: { xs: '0%', sm: '0%', md: '90%', lg: '90%', xl: '90%', },
						ml: { xs: '12%', sm: '12%', md: '12%', lg: '12%', xl: '12%' },
					}}
				>
					<CardActionArea sx={{ height: '100%', position: 'relative' }}>

						<CardContent>
							<ThemeProvider theme={theme}>
								<Typography gutterBottom variant="h6" component="div" sx={{ ml: 10 }} >
									{props.item.name}
								</Typography>
							</ThemeProvider>
						</CardContent>
					</CardActionArea>
				</Card>

				<Box
					sx={{
						ml: { xs: '0', sm: '2%', md: '5%', lg: '0', xl: '1%' },
						width: { xs: '100px', sm: '110px', md: '120px', lg: '120px', xl: '27%' },
						position: 'absolute',
						top: '10%',
					}}
				>
					{
						<Card
							sx={{
								height: {
									xs: '8rem',
									sm: '10rem',
									md: '12rem',
									lg: '10rem',
									xl: '12rem'
								},
								width: {
									xs: '6rem',
									sm: '7rem',
									md: '8rem',
									lg: '7rem',
									xl: '7rem'
								},
								borderRadius: '28px',
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								bgcolor: props.item.id == 2 ? '#23408e' : '#ffffff'
							}}
						>
							<CardMedia
								component="img"
								image={props.item.logo_url ? props.item.logo_url : require(`/src/assets/partners_logo/${props.item.logo_static}`)}
								alt={props.item.name}
								sx={{
									height: '70%',
									width: '100%',
								}}
							/>
						</Card>
					}
				</Box>
			</Box>
		);
	}

	return (
		<Swiper
			slidesPerView={3}
			spaceBetween={10}
			pagination={{ clickable: true }}
			autoplay={{
				delay: 3000,
				disableOnInteraction: false,
			}}
			loop={true}
			modules={[Pagination, Autoplay, Navigation, Thumbs]}
			style={{
				width: '100%',
				height: '100%',
			}}
		>
			<Box width={'100%'} justifyContent="center" alignItems="center" display="flex"
				sx={{
					flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' },
					mt: 2,
					mb: 2
				}}
			>
				{company.map((item, i) => <SwiperSlide key={i}><GeneralSectors key={i + 'CustomCard'} item={item} /></SwiperSlide>)}
			</Box>
		</Swiper>
	);
}

export default CompCompanies;