import React, { useRef, useState, useEffect } from 'react';
import { Box, Typography, ButtonBase, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setConsultants } from '../app/slices/consultants_slice';
import { useQuery, gql } from '@apollo/client';
import { Swiper, SwiperSlide } from 'swiper/react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/material/styles';
import { getConsultant } from '../api/main';

import 'swiper/css';
import 'swiper/css/pagination';
import '../../src/index.css';

import { Autoplay, Navigation, Pagination, Thumbs } from 'swiper/modules';

const theme = createTheme();
let carosuel_show = 0;

const GET_CONSULTANTS = gql`
  query GET_CONSULTANTS {
    allConsultants {
      consultantId
      urlName
      displayName
      familyName
      firstName
      lastName
      profileImg
      title
      levelId {
        levelCode
        levelName
      }
    }
  }
`;

const useScroll = (levelRefs) => {
  const [isScrolled, setIsScrolled] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  });

  const handleScroll = () => {
    for (let i = 0; i <= 5; i++) {
      if (levelRefs[i].current) {
        const rect = levelRefs[i].current.getBoundingClientRect();
        setIsScrolled((prev) => ({ ...prev, [i]: rect.top <= window.innerHeight / 2 }));
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return isScrolled;
};

const CompConsultants = () => {
  const navigate = useNavigate();
  const [consultant, setConsultant] = useState([]);
  const state_data = useSelector((state) => state.consultants.value);
  const dispatch = useDispatch();
  const { loading, error, data } = useQuery(GET_CONSULTANTS);
  // For language selection
  const language = useSelector((state) => state.app.value.language);


  // For carosuel
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isLg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));

  if (isXs) {
    // Code for 'xs' screen size
    carosuel_show = 1;
  } else if (isSm) {
    // Code for 'sm' screen size
    carosuel_show = 2;
  } else if (isMd) {
    // Code for 'md' screen size
    carosuel_show = 3;
  } else if (isLg) {
    // Code for 'lg' screen size
    carosuel_show = 4;
  } else if (isXl) {
    // Code for 'xl' screen size
    carosuel_show = 5;
  }

  const levelRefs = {
    0: useRef(null),
    1: useRef(null),
    2: useRef(null),
    3: useRef(null),
    4: useRef(null),
    5: useRef(null),
  };

  useEffect(() => {
    if (data) {
      dispatch(setConsultants(data.allConsultants));
    }
  }, [data, dispatch]);

  useEffect(() => {
    getConsultant(language).then(res => {
      setConsultant(res.data);
    });
  }, [language]);

  const generateConsultants = (item) => (
    <ButtonBase key={item.consultantId + 'buttonbase'}
      onClick={() => {
        if (item.urlName !== 'zolzaya') {
          navigate(`/consultant/${item.urlName}`)
        };
        window.scrollTo(0, 0);
      }
      }
    >
      <Box
        key={item.consultantId}
        sx={{
          height: { xs: '20rem', sm: '20rem', md: '20rem', lg: '20rem', xl: '20rem' },
          width: '15rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          p: 1,
        }}
      >
        <Box sx={{ height: '80%', width: '100%', ml: 1, mr: 1 }}>
          <Box
            component="img"
            alt="Profile"
            src={item.profileImg}
            sx={{
              height: '100%',
              width: '100%',
              objectFit: 'cover',
              borderTopLeftRadius: '1rem',
              borderTopRightRadius: '1rem',
            }}
          />
        </Box>
        <Box
          sx={{
            height: '20%',
            width: '100%',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            borderBottomLeftRadius: '1rem',
            borderBottomRightRadius: '1rem',
            bgcolor: '#1b3b48',
            ml: 1,
            mr: 1,
          }}
        >
          <Typography sx={{ color: 'white', ml: 1, mr: 1, fontSize: '16px' }}>{item.displayName}</Typography>
          <Typography sx={{ color: 'white', ml: 1, mr: 1, fontSize: '12px' }}>{item.title}</Typography>
        </Box>
      </Box>
    </ButtonBase>
  );

  const levelTexts = {
    1: { 'en': 'Executive Director', 'mn': 'Гүйцэтгэх захирал', 'cn': '执行董事' },
    2: { 'en': 'Partner Advocate', 'mn': 'Партнер өмгөөлөгч', 'cn': '合作伙伴倡导者' },
    3: { 'en': 'Advisor', 'mn': 'Зөвлөгчид', 'cn': '顾问' },
    4: { 'en': 'Branch management member', 'mn': 'Салбар хариуцсан гишүүд', 'cn': '分会管理成员' },
    5: { 'en': 'Advocate', 'mn': 'Өмгөөлөгчид', 'cn': '主张者' },
  };

  const generateComponent_upper = (array) => {
    if (array.length === 0) {
      return null
    }

    const boxRef = levelRefs[parseInt(array[0].levelId.levelCode)];
    const boxWidth = boxRef.current ? boxRef.current.getBoundingClientRect().width : 'auto';

    return (
      <Box
        style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center'
        }}
        sx={{
          display: 'flex',
          height: { xs: '44rem', sm: '25rem', md: '25rem', lg: '25rem', xl: '25rem' },
          flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' },
          pr: parseInt(array[0].levelId.levelCode) !== 1 ? 2 : 0,
          pl: parseInt(array[0].levelId.levelCode) === 1 ? 2 : 0
        }}
      >
        <Box sx={{ mr: { xs: 6, sm: 2, md: 3, lg: 4, xl: 5 } }}>
          <Box
            style={{
              width: '15rem',
              //borderBottom: isScrolled[0] ? '2px solid black' : 'none',
              borderBottom: '2px solid black',
              transition: 'border 0.2s ease-in-out',
              textAlign: 'left',
              pb: 1,
            }}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="body">
              {levelTexts[parseInt(array[0].levelId.levelCode)][language]}
            </Typography>
          </Box>
          <Box
            sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' } }}>
            {generateConsultants(array[0])}
          </Box>
        </Box>

        <Box sx={{ mr: { xs: 6, sm: 2, md: 3, lg: 4, xl: 5 } }}>
          <Box
            style={{
              width: '15rem',
              //borderBottom: isScrolled[0] ? '2px solid black' : 'none',
              borderBottom: '2px solid black',
              transition: 'border 0.2s ease-in-out',
              textAlign: 'left',
              pb: 1,
            }}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="body">
              {levelTexts[parseInt(array[1].levelId.levelCode)][language]}
            </Typography>
          </Box>
          <Box
            sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' } }}>
            {generateConsultants(array[1])}
          </Box>
        </Box>

      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={4} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }}>
        {consultant.map((row, index) =>
          <Grid key={index} item sx={{ width: { xs: '50%', sm: '33%', md: '25%', lg: '30%', xl: '20%' } }}>
            <Box sx={{ height: '22rem', backgroundColor: '#1b3b48', borderTopLeftRadius: '2rem', borderBottomRightRadius: '2rem', overflow: 'hidden' }}>
              <Box
                sx={{
                  height: '12rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                  overflow: 'hidden',
                  borderTopLeftRadius: '2rem',
                  borderTopRightRadius: '2rem',
                }}
              >
                <Box
                  component="img"
                  alt="profile"
                  src={row.image}
                  sx={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                    objectPosition: 'top',
                  }}
                />
              </Box>
              <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography sx={{ fontWeight: 'bold', color: 'white' }} align='center' >{row.display_name}</Typography>
                <Typography sx={{ color: 'white', pl: 1, pr: 1 }} align='center'>{row.title}</Typography>
              </Box>
            </Box>
          </Grid>)}
      </Grid>
    </Box>
  );
};

export default CompConsultants;
