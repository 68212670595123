import React, { useEffect } from 'react';
import { useRive, Layout, Fit, Alignment } from '@rive-app/react-canvas';
import { Box, Divider, Typography } from '@mui/material';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setNconsult } from '../../app/slices/nconsult_slice';

import CompCompanies from '../../components/compCompanies';
import CompConsultants from '../../components/compConsultants';
import PartnersSliders from '../../components/PartnersSlider';
import Operations from '../../components/Operations';
import Header from '../Header';
import Container from '@mui/material/Container';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import logoMn from '../../assets/company info/Undesnii-zuvlugch-white.png';
import logoEn from '../../assets/company info/Undesnii-zuvlugch-white-ENG.png'

import CustomFooter from '../Footer';
import News from '../../components/News';

const theme = createTheme();
theme.typography.h2 = {
  [theme.breakpoints.up('xs')]: { fontSize: '1rem' },
  [theme.breakpoints.up('sm')]: { fontSize: '2rem' },
  [theme.breakpoints.up('md')]: { fontSize: '2rem' },
  [theme.breakpoints.up('lg')]: { fontSize: '2rem' },
  [theme.breakpoints.up('xl')]: { fontSize: '2rem', color: '#1b3b48' },
};


function HomePage() {
  // Load the Nconsult data
  const data = useSelector((state) => state.nconsult.value);
  // For language selection
  const language = useSelector((state) => state.app.value.language);
  const dispatch = useDispatch();

  // Graphql get data
  //const { loading, error, data_graphql } = useQuery(BOOKS_QUERY);

  //Statics???????
  const main_description = {
    'mn': 'Манай хуулийн фирм "Үндэсний зөвлөгч" зөвлөх үйлчилгээний нэг хэсэг бөгөөд "Санхүү бизнесийн эрх зүйн судалгааны хүрээлэн", "Санхүүгийн зах зээлийн үндэсний зөвлөл", "Ай Жоб" хүний нөөцийн зуучлалын компаниудтайгаар үйл ажиллагаа явуулж байна.',
    'en': 'Our law firm is a part of the "National Consultant" consulting service and works with "Financial Business Legal Research Institute", "National Council of Financial Markets" and "iJob" human resources brokerage companies.',
    'cn': '我们的律师事务所是“国家顾问”咨询服务的一部分，并与“金融商业法律研究所”、“国家金融市场委员会”和“iJob”人力资源经纪公司合作。'
  }
  const subtitle = {
    'news': {
      'en': 'News',
      'mn': 'Мэдээ мэдээлэл',
      'cn': '消息'
    },
    'company': {
      'en': 'Companies',
      'mn': 'Байгууллагууд',
      'cn': '公司'
    },
    'consultants': {
      'en': 'Members',
      'mn': 'Зөвлөхүүд',
      'cn': '会员'
    },
    'partners': {
      'en': 'Partners',
      'mn': 'Хамтрагчид',
      'cn': '伙伴'
    },
    'services': {
      'en': 'Legal advice',
      'mn': 'Хууль зүйн зөвлөгөө',
      'cn': '法律咨询'
    }
  }

  useEffect(() => {
    dispatch(setNconsult(data));
  }, [data]);

  const { rive, RiveComponent } = useRive({
    src: 'map.riv',
    stateMachines: "main_state",
    layout: new Layout({
      fit: Fit.FitWidth,
      alignment: Alignment.Center
    }),
    autoplay: true,
  });

  // The component divider 
  const component_divider = <Box sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px',
    width: '100%',
    opacity: 0.1,
    mt: 4,
    mb: 4,
  }}>
    <Divider variant="middle" sx={{ width: '50%', border: '0.5px solid' }} />
  </Box>

  return (
    <>
      <Container maxWidth="false" style={{ backgroundColor: '#1b3b48', overflowX: 'hidden' }}>
        <Header pageName={'home'} />
        <Box sx={{
          height: { xs: '50vh', sm: '60vh', md: '90vh', lg: '90vh', xl: '90vh' },
          width: '100%',
          flexDirection: 'column'
        }} >
          { /*************************** Top logo *************************/}
          <Box id={'home'}
            sx={{ height: '30%', width: '100%', alignItems: "center", display: 'flex', flexDirection: 'column', justifyContent: "center" }}
          >
            <Box
              component="img"
              alt="logo"
              src={language === 'mn' ? logoMn : logoEn}
              sx={{
                mt: { xs: '10%', sm: '', md: '', lg: '', xl: '' },
                mb: { xs: '10%', sm: '', md: '', lg: '15%', xl: '' },
                height: { xs: '60%', sm: '55%', md: '50%', lg: '50%', xl: '40%' },
                width: 'auto', maxWidth: '100%',
                borderTopLeftRadius: '1rem',
                borderTopRightRadius: '1rem'
              }}
            >
            </Box>
          </Box>

          { /*************************** Map *************************/}
          <Box sx={{
            height: { xs: '70%', sm: '70%', md: '40%', lg: '50%', xl: '50%' },
            width: { xs: '80%', sm: '90%', md: '100%', lg: '100%', xl: '100%' },
            display: "flex",
            justifySelf: 'center',
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}>
            <Box sx={{
              height: { xs: '150%', sm: '100%', md: '100%', lg: '100%', xl: '100%' },
              width: { xs: '180%', sm: '150%', md: '100%', lg: '95%', xl: '80%' },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: 'relative'
            }}>
              {/* <div
								style={{
									right: 0,
									position: 'absolute',
									width: '35%',
									height: '19%',
									backgroundColor: 'red',
									bottom: 50,
								}}
								sx={{
									bottom: {
										xs: 60, // Small screens
										sm: 30,
										md: 40,
										lg: 10,
										xl: 20, // Extra large screens
									},
								}}
								>asd</div> */}
              <RiveComponent
                style={{
                  height: '100%',
                  width: '100%',
                  maxWidth: '80%',
                }}
              //onMouseEnter={() => rive && rive.play()}
              //onMouseLeave={() => rive && rive.pause()}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{
          borderRadius: '30px', display: 'flex', flexDirection: 'column', bgcolor: '#ffffff',
          px: { xs: '20px', sm: '60px', md: '60px', lg: '100px', xl: '8rem' },
          mx: { xs: '0', sm: '0', md: '70px', lg: '100px', xl: '130px' },
        }}>
          <Box sx={{ justifyContent: 'center', mt: '3rem' }}>
            <Typography variant="body1" align="justify"  >
              {main_description[language]}
            </Typography>
          </Box>
          {component_divider}

          <Box>
            <Box display='flex' flexDirection={{ xs: "column", sm: "row" }} width="100%">
              <Box flex={8}>
                <Box>
                  <Box id='company' sx={{ display: 'flex', flexDirection: 'row' }}>
                    <ThemeProvider theme={theme}>
                      <Typography variant='h2'>{subtitle.company[language]}</Typography>
                    </ThemeProvider>
                  </Box>
                  <CompCompanies />
                </Box>
                {component_divider}
                <Box id='consultants' sx={{ mt: { xs: 10, sm: 5 }, mb: 5, display: 'flex', flexDirection: 'row' }} >
                  <ThemeProvider theme={theme}>
                    <Typography variant='h2'>{subtitle.consultants[language]}</Typography>
                  </ThemeProvider>
                </Box>
                <CompConsultants />
              </Box>
              <Box
                flex={4}
                sx={{
                  height: { xs: "auto", sm: "60rem" }, 
                  overflowY: { xs: "visible", sm: "auto" },
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography variant='h2'>{subtitle.news[language]}</Typography>
                </ThemeProvider>
                <News lang={language} />
              </Box>
            </Box>
            <Box>
              {component_divider}
              <Box id='partners' sx={{ mb: 5, display: 'flex', flexDirection: 'row' }} >
                <ThemeProvider theme={theme}>
                  <Typography variant='h2'>{subtitle.partners[language]}</Typography>
                </ThemeProvider>
              </Box>
              <PartnersSliders />
              {component_divider}
              <Box id='services' sx={{ mb: 5, display: 'flex', flexDirection: 'row' }} >
                <ThemeProvider theme={theme}>
                  <Typography variant='h2'>{subtitle.services[language]}</Typography>
                </ThemeProvider>
              </Box>
              <Operations />
            </Box>
          </Box>
        </Box>
        <CustomFooter id='contact' />
      </Container>
    </>
  );
}

export default HomePage;
