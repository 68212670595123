import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getNews } from '../api/main';

const News = ({ lang }) => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getNews(lang).then(res => {
      setData(res.data);
    });
  }, [lang]);

  const handleOpen = (id) => {
    navigate(`/news/${id}`);
  };

  return (
    <>
      {data.map(news => (
        <Box onClick={() => {handleOpen(news.id)}} display='flex' gap={1} sx={{ flexDirection: 'row', p: { xs: 1, sm: 2 } }}>
          <Box
            flex={4}
            sx={{
              height: "6rem",
              width: "10rem",
              overflow: "hidden",
              borderTopLeftRadius: "1rem",
              borderTopRightRadius: "1rem"
            }}
          >
            <Box
              component='img'
              alt='news'
              src={news.imageurl}
              sx={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
              }}
            />
          </Box>
          <Box
            flex={8}
          >
            <Typography variant='subtitle2'>{news.topic}</Typography>
            <Typography variant='caption'>{news.postedat.split('T')[0] + ' ' + news.postedat.split('T')[1]}</Typography>
          </Box>
        </Box>
      ))}
    </>
  );
}

export default News;